import {
  FOG_20,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import {
  AvgInterestAnnotation,
  OuInterestAnnotation,
  OurCalculationsNumber,
  OurCalculationsOperator,
  OurCalculationsTitle,
  SavingsAnnotation
} from './typography';

const OurCalculationsWrapper = styled(OwnUpGridContainer)`
  align-items: center;
  margin: 0;
  background-color: ${FOG_20};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    width: 520px;
  }
  padding: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }) => theme.spacing(4)}px;
  }
`;

const EqualsLine = styled.hr`
  margin: 0;
`;

export const OurCalculations = () => (
  <OurCalculationsWrapper variant="legacy">
    <OwnUpGridItem xs={12}>
      <OurCalculationsTitle>Our calculation</OurCalculationsTitle>
    </OwnUpGridItem>
    {/* Row Break */}
    <OwnUpGridOffset xs={1} />
    <OwnUpGridItem xs={3}>
      <OurCalculationsNumber>$163,937</OurCalculationsNumber>
    </OwnUpGridItem>
    <OwnUpGridItem xs={8}>
      <AvgInterestAnnotation>Interest cost of average loan in U.S.</AvgInterestAnnotation>
    </OwnUpGridItem>
    {/* Row Break */}
    <OwnUpGridItem xs={1}>
      <OurCalculationsOperator>&minus;</OurCalculationsOperator>
    </OwnUpGridItem>
    <OwnUpGridItem xs={3}>
      <OurCalculationsNumber>$135,767</OurCalculationsNumber>
    </OwnUpGridItem>
    <OwnUpGridItem xs={8}>
      <OuInterestAnnotation>Interest cost of loan in our marketplace</OuInterestAnnotation>
    </OwnUpGridItem>
    {/* Row Break */}
    <OwnUpGridItem xs={12}>
      <EqualsLine />
    </OwnUpGridItem>
    {/* Row Break */}
    <OwnUpGridOffset xs={1} />
    <OwnUpGridItem xs={3}>
      <OurCalculationsNumber>$28,170</OurCalculationsNumber>
    </OwnUpGridItem>
    <OwnUpGridItem xs={8}>
      <SavingsAnnotation>Average savings over the life of a loan</SavingsAnnotation>
    </OwnUpGridItem>
  </OurCalculationsWrapper>
);
