import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpThemeProvider,
  ownUpWhiteTheme,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { OurCalculations } from './our-calculations';

const ThemeBackgroundSection = styled.section`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};
`;

const SavingsMethodologyHeadline = styled(OwnUpHeadlineBook)`
  padding-top: ${({ theme }) => theme.spacing(5)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding-top: ${({ theme }) => theme.spacing(8)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(10)}px;
  }
`;

const SavingsMethodologyBody = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(8)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding-bottom: ${({ theme }) => theme.spacing(10)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-bottom: ${({ theme }) => theme.spacing(15)}px;
  }
`;

const SavingsMethodologyWrapper = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpGridWrapper>
    <OwnUpGridContainer variant="legacy">
      <OwnUpGridOffset xs={0} md={1} />
      <OwnUpGridItem xs>{children}</OwnUpGridItem>
      <OwnUpGridOffset xs={0} md={2} lg={3} />
    </OwnUpGridContainer>
  </OwnUpGridWrapper>
);

export const SavingsMethodology = () => (
  <OwnUpThemeProvider theme={ownUpWhiteTheme}>
    <ThemeBackgroundSection>
      <SavingsMethodologyWrapper>
        <SavingsMethodologyHeadline variant="h1">Savings methodology</SavingsMethodologyHeadline>
        <SavingsMethodologyBody>
          <OwnUpBody variant="body1">
            Customers who secure a mortgage from the Own Up® marketplace save an average of $28,170
            in interest over the life of their loan.
          </OwnUpBody>

          <OurCalculations />

          <OwnUpBody variant="body1">
            To find the total interest cost on the average loan, we use the Optimal Blue® Mortgage
            Market Indices (one of the most widely used market averages) from January 1st 2021 to
            December 31st 2021 to determine the national average interest rate on the same day as
            our comparison loan was secured from the Own Up® marketplace.
          </OwnUpBody>

          <OwnUpBody variant="body1">
            We use that national average interest rate to determine the total interest cost of the
            average loan secured in 2021 at Own Up of $304,450. Interest can vary based on many
            factors, so we limited the analyses to 30 year conventional mortgages in 2021 but did
            not normalize for other factors that can influence rate (e.g., credit score, state,
            property type).
          </OwnUpBody>
        </SavingsMethodologyBody>
      </SavingsMethodologyWrapper>
    </ThemeBackgroundSection>
  </OwnUpThemeProvider>
);
